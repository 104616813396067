import CookieManager from '@kvass/cookie-manager'
import VueGtm from 'vue-gtm'
import Vue from 'vue'
import router from './router'
import { getCookie, LoadFacebookPixel } from './utils'
import { i18n } from '@/i18n'

let consents = [
  {
    id: 'required',
    required: true,
    title: i18n.t('consentRequiredTitle'),
    description: i18n.t('consentRequiredDescription'),
  },
  {
    id: 'statistics',
    title: i18n.t('consentStatisticsTitle'),
    description: i18n.t('consentStatisticsDescription'),
  },
  {
    id: 'marketing',
    title: i18n.t('consentMarketingTitle'),
    description: i18n.t('consentMarketingDescription'),
  },
]

const CM = new CookieManager({
  title: i18n.t('consentDialogTitle'),
  description: i18n.t('consentDialogDescription'),
  labels: {
    acceptAll: i18n.t('consentDialogLabelAcceptAll'),
    confirm: i18n.t('consentDialogLabelConfirm'),
    decline: 'Kun nødvendige',
  },
  consents,
  onChange(res) {
    let dataLayer = window.dataLayer || []

    let blocked = consents.map(c => c.id).filter(c => !res.includes(c))

    dataLayer.push(...blocked.map(c => ({ event: `consent:block:${c}` })))

    //load facebook pixel if marketing consent
    if (!blocked.includes('marketing') && KvassConfig.facebookPixel)
      LoadFacebookPixel(KvassConfig.facebookPixel)

    let id = getCookie('kvass.gtm')
    if (!id) return

    Vue.use(VueGtm, {
      id,
      debug: Boolean(getCookie('gtm.debug')),
      vueRouter: router,
    })

    if (Kvass) Kvass.emit('consent:change', res)
  },
})

if (Kvass) {
  Kvass.on('consent:show', CM.show)
  Kvass.on('consent:get', cb => cb(CM.getConsents()))
}

export default CM
